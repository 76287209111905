<template>
    <div class="col-lg-6">
        <article class="post">
            <figure class="featured-post cp"
            :style="
                {
                backgroundImage:
                    `url(${blog.imageUrl})`
                }">
        </figure>
            <div class="post-content">
                <div class="cat-meta">
                <a @click="loadBlog">{{ convertDate(blog.dateAdded) }}</a>
                </div>
                <h3><a @click="loadBlog" class="cp">
                    {{isCurrentLanguageBs ? blog.title : blog.titleEn}}</a></h3>
                <p>{{isCurrentLanguageBs ? content : contentEn}}...</p>
                <div
                class="post-footer d-flex
                justify-content-between align-items-center">
                <div class="post-btn cp">
                    <a @click="loadBlog"
                    class="round-btn cp">{{ 'readMore' | translate }}</a>
                </div>
                </div>
            </div>
        </article>
    </div>
  </template>
<script>
import convertDate from '@/mixins/dateConvert';

export default {
  name: 'HomeBlogItem',

  mixins: [convertDate],

  data() {
    return {
    };
  },

  props: {
    blog: {
      type: Object,
      required: true,
    },
  },

  computed: {
    content() {
      const text = this.blog.content.replace(/(<([^>]+)>)/ig, '');
      return text.substring(0, 100);
    },

    contentEn() {
      const text = this.blog.contentEn.replace(/(<([^>]+)>)/ig, '');
      return text.substring(0, 100);
    },
  },

  methods: {
    loadBlog() {
      this.$router.push({ name: 'blog', params: { id: this.blog.blogId } });
      this.$store.dispatch('setCurrentBlogId', this.blog.blogId);
    },
  },
};
</script>

  <style>
  </style>
