var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"site-main",attrs:{"id":"content"}},[_c('section',{staticClass:"inner-page-wrap"},[_c('div',{staticClass:"inner-banner-wrap"},[_c('div',{staticClass:"inner-baner-container",style:({
          backgroundImage:
            `url(${require('@/assets/images/aboutUsBanner.webp')})`
        })},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"inner-banner-content"},[_c('h1',{staticClass:"page-title"},[_vm._v(_vm._s(_vm._f("translate")('about')))])])])])]),_c('div',{staticClass:"inner-about-wrap"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"about-content"},[_c('figure',{staticClass:"about-image"},[_c('img',{attrs:{"src":require("../../assets/images/aboutImage.webp"),"alt":""}}),_c('div',{staticClass:"about-image-content"},[_c('h3',[_vm._v(_vm._s(_vm._f("translate")('aboutTitle'))+"!")])])]),_c('p',[_vm._v(_vm._s(_vm.translate('aboutText')))])])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"icon-box"},[_vm._m(0),_c('div',{staticClass:"icon-box-content"},[_c('h3',[_vm._v(_vm._s(_vm._f("translate")('affordableTours')))]),_c('p',[_vm._v(_vm._s(_vm._f("translate")('affordableToursInfo')))])])]),_c('div',{staticClass:"icon-box"},[_vm._m(1),_c('div',{staticClass:"icon-box-content"},[_c('h3',[_vm._v(_vm._s(_vm._f("translate")('bestGuides')))]),_c('p',[_vm._v(_vm._s(_vm._f("translate")('bestGuidesInfo')))])])]),_c('div',{staticClass:"icon-box"},[_vm._m(2),_c('div',{staticClass:"icon-box-content"},[_c('h3',[_vm._v(_vm._s(_vm._f("translate")('Eco-friendly')))]),_c('p',[_vm._v(_vm._s(_vm._f("translate")('ecoFriendlyInfo')))])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-icon"},[_c('i',{staticClass:"fas fa-umbrella-beach",attrs:{"aria-hidden":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-icon"},[_c('i',{staticClass:"fas fa-user-tag",attrs:{"aria-hidden":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box-icon"},[_c('i',{staticClass:"fas fa-leaf",attrs:{"aria-hidden":"true"}})])
}]

export { render, staticRenderFns }