<template>
  <!-- site header html end  -->
  <main id="content" class="site-main">
            <!-- ***home banner html start form here*** -->
            <vue-flux
                :options="vfOptions"
                :images="sliderUrls"
                :transitions="vfTransitions"
                :captions="vfCaptions"
                ref="slider">
                <template v-slot:preloader>
                  <flux-preloader />
                </template>
                <div class="overlay"></div>
            </vue-flux>
            <!-- ***home banner html end here*** -->
            <InquireNow />
            <!-- ***Home package html start from here*** -->
            <section class="home-package">
               <div class="container">
                  <div class="row">
                     <div class="col-lg-8 offset-lg-2 text-sm-center">
                        <div class="section-heading">
                           <h2 class="section-title">{{ 'checkoutOurTours' | translate }}</h2>
                           <p>{{ 'homeToursInfo' | translate }}</p>
                        </div>
                     </div>
                  </div>
                  <div class="package-section">
                     <TourListItem
                        v-for="(tour, index) in recommendedTours" :key="index" :tour="tour" />
                     <div class="section-btn-wrap text-center">
                        <router-link class="round-btn" to="/tours">
                           {{ 'viewAllTours' | translate }}</router-link>
                     </div>
                  </div>
               </div>
            </section>
            <!-- ***Home package html end here*** -->
            <!-- ***Home callback html start from here*** -->
            <section class="home-callback bg-img-fullcallback"
            :style="{ backgroundImage: `url(${require('@/assets/images/videohome.jpg')})` }">
               <div class="overlay"></div>
               <div class="container">
                  <div class="row">
                     <div class="col-lg-8 offset-lg-2 text-center">
                        <div class="callback-content">
                           <div class="video-button">
                              <a  id="video-container" data-fancybox="video-gallery" href="https://youtu.be/hWKNocZ4q44">
                                 <i class="fas fa-play"></i>
                              </a>
                           </div>
                           <h2 class="section-title">{{ 'customizedTourInfo' | translate }} !!</h2>
                           <p></p>
                           <div class="callback-btn">
                              <a @click.stop="showCustomizedTourSidebar" class="round-btn cp">
                                {{ 'customizedTour' | translate }}
                              </a>
                              <router-link
                                 class="outline-btn outline-btn-white cp"
                                 to="/contact">{{translate('contactUs')}}
                              </router-link>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <!-- ***Home callback html end here*** -->
            <!-- ***Home counter html start from here*** -->
            <div class="home-counter">
               <div class="container">
                  <div class="counter-wrap">
                     <div class="counter-item">
                        <span class="counter-no">
                           <span class="counter">3</span>K+
                        </span>
                        <span class="counter-desc">
                           {{ 'satisfiedCustomers' | translate }}
                        </span>
                     </div>
                     <div class="counter-item">
                        <span class="counter-no">
                           <span class="counter">18</span>+
                        </span>
                        <span class="counter-desc">
                           {{ 'activeMembers' | translate }}
                        </span>
                     </div>
                     <div class="counter-item">
                        <span class="counter-no">
                           <span class="counter">10</span>+
                        </span>
                        <span class="counter-desc">
                           {{ 'tourDestination' | translate }}
                        </span>
                     </div>
                     <div class="counter-item">
                        <span class="counter-no">
                           <span class="counter">5</span>+
                        </span>
                        <span class="counter-desc">
                           {{ 'travelGuides' | translate }}
                        </span>
                     </div>
                  </div>
               </div>
            </div>
            <!-- ***Home counter html end here*** -->
            <!-- ***Home gallery html start from here*** -->
            <section class="home-gallery">
               <div class="container">
                  <div class="row">
                     <div class="col-lg-8 offset-lg-2 text-sm-center">
                        <div class="section-heading">
                           <h5 class="sub-title">{{ 'photoGallery' | translate }}</h5>
                           <h2 class="section-title">{{ 'photosTravellers' | translate }}</h2>
                           <p>{{ 'homeGalleryInfo' | translate }}</p>
                        </div>
                     </div>
                  </div>
                  <div class="gallery-section">
                     <div class="gallery-container">
                        <figure v-for="(image, index) in gallery" :key="index" class="gallery-item">
                           <img
                              :src="image.url" alt="Visit Jablanica" class="gallery-image" />
                        </figure>
                     </div>
                  </div>
               </div>
            </section>
            <!-- ***Home gallery html end here*** -->
            <!-- ***Home blog html start from here*** -->
            <section class="home-blog">
               <div class="container">
                  <div class="section-heading d-sm-flex align-items-center justify-content-between">
                     <div class="heading-group">
                        <h5 class="sub-title">{{ 'latestBlog' | translate }}</h5>
                        <h2 class="section-title">{{ 'ourRecentPosts' | translate }}</h2>
                        <p>{{ 'homeBlogInfo' | translate }}</p>
                     </div>
                     <div class="heading-btn">
                        <a href="blog-archive.html"
                          class="round-btn">{{ 'viewAllBlog' | translate }}</a>
                     </div>
                  </div>
                  <div class="blog-section">
                     <div class="row gx-4">
                        <HomeBlogItem v-for="blog in lastPosts" :key="blog.blogId" :blog="blog" />
                     </div>
                  </div>
               </div>
            </section>
            <!-- ***Home blog html end here*** -->
            <!-- ***Home callback html start from here*** -->
            <section class="home-callback bg-color-callback primary-bg">
               <div class="container">
                  <div class="row align-items-center">
                     <div class="col-md-8">
                        <h5 class="sub-title">{{ 'contactUs' | translate }}</h5>
                        <h2 class="section-title">{{ 'contactHomeTitle' | translate }}</h2>
                        <p>{{ 'contactHomeInfo' | translate }}.</p>
                     </div>
                     <div class="col-md-4 text-md-end">
                        <router-link
                           class="outline-btn outline-btn-white"
                           to="/contact">
                           {{translate('contactUs')}}
                        </router-link>
                     </div>
                  </div>
               </div>
            </section>
            <!-- ***Home callback html end here*** -->
         </main>
</template>

<script>

import { VueFlux, FluxPreloader } from 'vue-flux';
import InquireNow from '@/components/widgets/InquireNow';
import HomeBlogItem from '@/components/widgets/HomeBlogItem';
import TourListItem from '@/components/widgets/TourListItem';

export default {
  name: 'Home',

  components: {
    VueFlux,
    FluxPreloader,
    InquireNow,
    HomeBlogItem,
    TourListItem,
  },

  data() {
    return {
      vfOptions: {
        autoplay: true,
        lazyLoad: true,
        lazyLoadAfter: 3,
      },
      vfTransitions: ['kenburn'],
      vfCaptions: [
        'Caption for image 1',
        'Caption for image 2',
        'Caption for image 3',
      ],
    };
  },
  mounted() {
  },
  computed: {
    sliderUrls() {
      return this.sliderImages.map((i) => i.url);
    },
    sliderImages() {
      return this.$store.getters.sliderImages;
    },
    recommendedTours() {
      return this.$store.getters.recommendedTours;
    },
    gallery() {
      return this.$store.getters.galleryImages.slice(-6);
    },
    lastPosts() {
      return this.$store.getters.blogs.slice(-2);
    },
  },
  methods: {
    showCustomizedTourSidebar() {
      this.$store.dispatch('setCustomizeTourSidebar', true);
    },
  },
};
</script>
<style scoped>
.gallery-image {
  max-width: 100%;
  display: block;
  border-radius: 10px;
}

.gallery-item {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}

.gallery-item > img {
  grid-row: 1 / -1;
  grid-column: 1;
}

.gallery-container {
  column-count: 3;
  column-gap: 5px;
}
</style>
