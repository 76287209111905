<template>
<main id="content" class="site-main">
    <section class="contact-inner-page">
        <!-- ***Inner Banner html start form here*** -->
        <div class="inner-banner-wrap">
          <div class="inner-baner-container"
          :style="
                {
                backgroundImage:
                    `url(${require('@/assets/images/contactBanner.webp')})`
                }">
              <div class="container">
                <div class="inner-banner-content">
                    <h1 class="page-title">{{ 'contactUs' | translate }}</h1>
                </div>
              </div>
          </div>
        </div>
        <!-- ***Inner Banner html end here*** -->
        <!-- ***contact section html start form here*** -->
        <div class="inner-contact-wrap">
          <div class="container">
              <div class="row">
                <div class="col-lg-6">
                    <div class="section-heading">
                      <h5 class="sub-title">{{ 'getInTouch' | translate }}</h5>
                      <h2 class="section-title">{{ 'fellFreeToContact' | translate }}</h2>
                      <p>{{ 'contactDesc' | translate }}</p>
                      <div class="social-icon">
                          <ul>
                            <li>
                              <a :href="social.facebook" target="_blank">
                                  <i class="fab fa-facebook-f" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li>
                              <a :href="social.instagram" target="_blank">
                                  <i class="fab fa-instagram" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li>
                              <a :href="social.youtube" target="_blank">
                                  <i class="fab fa-youtube" aria-hidden="true"></i>
                              </a>
                            </li>
                            <li>
                              <a :href="social.tripadvisor" target="_blank">
                                  <i class="fab fa-tripadvisor" aria-hidden="true"></i>
                              </a>
                            </li>
                          </ul>
                      </div>
                    </div>
                    <div class="contact-map">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10592.855276363387!2d17.746186343504295!3d43.62948920036189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475f5f85ca4ffe7d%3A0xfd5b5767b515f2f3!2sVisit%20Jablanica!5e0!3m2!1sen!2sba!4v1679245676908!5m2!1sen!2sba" width="600" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="contact-from-wrap primary-bg">
                      <contact-form />
                    </div>
                </div>
              </div>
          </div>
        </div>
        <!-- ***contact section html start form here*** -->
        <!-- ***iconbox section html start form here*** -->
        <div class="contact-details-section bg-light-grey">
          <div class="container">
              <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="icon-box border-icon-box">
                      <div class="box-icon">
                          <i aria-hidden="true" class="fas fa-envelope-open-text"></i>
                      </div>
                      <div class="icon-box-content">
                          <h4>{{ 'emailAddress' | translate }}</h4>
                          <ul>
                            <li>
                                <a href="mailto:info@visitjablanica.com">info@visitjablanica.com</a>
                            </li>

                          </ul>
                      </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="icon-box border-icon-box">
                      <div class="box-icon">
                          <i aria-hidden="true" class="fas fa-phone-alt"></i>
                      </div>
                      <div class="icon-box-content">
                          <h4>{{ 'phoneNumber' | translate }}</h4>
                          <ul>
                            <li>
                                <a href="tell:+38762376719">+38762 376 719</a>
                            </li>
                          </ul>
                      </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="icon-box border-icon-box">
                      <div class="box-icon">
                          <i aria-hidden="true" class="fas fa-map-marker-alt"></i>
                      </div>
                      <div class="icon-box-content">
                          <h4>{{ 'addressLocation' | translate }}</h4>
                          <ul>
                            <li>
                              Trg Oslobođenja SP 32
                            </li>
                            <li>
                                88420, Jablanica
                            </li>
                            <li>
                                Bosna i Hercegovina
                            </li>
                          </ul>
                      </div>
                    </div>
                </div>
              </div>
          </div>
        </div>
        <!-- ***iconbox section html end here*** -->
    </section>
  </main>
</template>

<script>
import ContactForm from '../widgets/ContactForm.vue';

export default {
  name: 'Contact',
  components: { ContactForm },
};
</script>

<style scoped>
.icon-box {
  min-height: 305px;
}
</style>
