<template>
  <main id="content" class="site-main">
  <!-- ***Inner Banner html start form here*** -->
  <div class="inner-banner-wrap">
      <div class="inner-baner-container"
      :style="{
        backgroundImage:
          `url(${require('@/assets/images/img7.jpg')})`
      }">
        <div class="container">
            <div class="inner-banner-content">
              <h1 class="page-title">Članci</h1>
            </div>
        </div>
      </div>
  </div>
  <!-- ***Inner Banner html end here*** -->
  <div class="archive-section blog-archive">
      <div class="container">
        <div class="row">
            <div class="col-lg-12 primary">
              <!-- blog post item html start -->
              <div class="grid blog-inner row">
                <div
                  class="grid-item col-md-6"
                  v-for="blog in blogs" :key="blog.blogId">
                    <BlogListItem :blog="blog" />
                </div>
              </div>
              <!-- blog post item html end -->
            </div>
        </div>
      </div>
  </div>
</main>
</template>
<script>

import BlogListItem from '@/components/widgets/BlogListItem';

export default {
  name: 'Blogs',

  components: {
    BlogListItem,
  },

  mounted() {
    this.$nextTick(() => {
      this.onPageLoad();
    });
  },

  data() {
    return {
    };
  },
  computed: {
    blogs() {
      return this.$store.getters.blogs;
    },
  },
  methods: {
  },
};
</script>
