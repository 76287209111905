<!-- eslint-disable no-tabs -->
<template>
<!-- ***Home search field html start from here*** -->
<div class="home-trip-search primary-bg">
  <div class="container">
      <div class="trip-search-inner d-flex">
        <div class="group-input">
          <label> {{translate('searchDestination')}}* </label>
          <select name="selectedTour" v-model="selectedTour" v-validate="'required'">
            <option value="" selected>{{ 'pleaseSelectTour' | translate}}</option>
            <option
              v-for="tour in toursDropdown"
              :key="tour.tourId"
              :value="tour.name">
              {{isCurrentLanguageBs ? tour.name : tour.nameEn}}</option>
          </select>
          <span
            v-show="errors.has('selectedTour')"
            class="invalid-feedback">
            {{ translate('requiredValid') }}
          </span>
        </div>
          <div class="group-input">
          <label> {{translate('personNo')}} </label>
          <input type="number"
              name="personNo" v-model="personNo" placeholder="10">
          </div>
          <div class="group-input width-col-3">
          <label> {{translate('startDate')}} </label>
          <date-picker
              class="input-date-picker"
              v-model="startDate"
              format="DD-MM-YYYY"
              type="date"
              placeholder="DD-MM-YYYY"
          ></date-picker>
          </div>
          <div class="group-input width-col-3">
              <label> {{translate('contact')}}* </label>
              <input type="text"
              name="email" v-model="email"
              :placeholder="translate('emailOrPhone')" v-validate="'required'">
              <span
                v-show="errors.has('email')"
                class="invalid-feedback">
                {{ translate('requiredValid') }}
              </span>
          </div>
          <div class="group-input width-col-3">
          <input type="submit"
              @click.stop="checkAvailability"
              name="travel-search" :value="translate('checkAvailability')">
          </div>
      </div>
  </div>
</div>
<!-- ***search search field html end here*** -->
</template>
<script>

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import emailjs from 'emailjs-com';

export default {
  name: 'InquireNow',

  components: { DatePicker },

  data() {
    return {
      selectedTour: '',
      email: '',
      personNo: 0,
      startDate: null,
    };
  },

  computed: {
    tours() {
      return this.$store.getters.tours || [];
    },
    toursDropdown() {
      return this.tours.map((tour) => ({
        tourId: tour.tourId,
        name: tour.name,
        nameEn: tour.nameEn,
      }));
    },
  },

  methods: {
    checkAvailability() {
      this.$validator.validateAll().then((result) => {
        if (result && this.selectedTour) {
          const templateParams = {
            from_name: '',
            form_emailphone: `${this.email}`,
            form_tour: this.selectedTour,
            form_date: this.startDate,
            form_personNo: this.personNo,
          };

          emailjs.send('service_7hn4vhc', 'template_4ebz93v', templateParams, 'b9Xjw4ic8AED2mAB_')
            .then(() => {
              this.$toast.success(this.translate('tourAvailabilityQuestioMessage'));
              // Reset form fields
              this.selectedTour = '';
              this.email = '';
              this.personNo = 0;
              this.startDate = null;
            }, () => {
              this.$toast.error(this.translate('tourAvailabilityQuestioErrorMessage'));
            });
        }
      });
    },
  },
};
</script>

<style scoped>
  .invalid-feedback {
    position: absolute;
    display: block;
    color: red;
    font-size: 14px;
    left: 24px;
  }
  .mx-datepicker {
    display: initial;
  }
</style>
