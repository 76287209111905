<template>
  <article class="post cp"
    @mouseenter="mouseEnterHandler"
    @mouseleave="mouseLeaveHandler"
    @click.stop="loadBlog"
  >
      <figure class="featured-post">
        <img :src="blog.imageUrl" alt="">
      </figure>
      <div class="post-content">
        <h3>
          <a @click.stop="loadBlog">
            {{isCurrentLanguageBs ? blog.title : blog.titleEn}}
          </a>
        </h3>
        <p>{{isCurrentLanguageBs ? content : contentEn}}...</p>
        <div class="post-footer d-flex justify-content-between align-items-center">
            <div class="post-btn">
              <a @click.stop="loadBlog" class="round-btn cp">{{ 'readMore' | translate }}</a>
            </div>
        </div>
      </div>
  </article>
</template>
<script>

export default {
  name: 'BlogListItem',

  data() {
    return {
      fullyLoaded: false,
      loading: false,
    };
  },

  props: {
    blog: {
      type: Object,
      required: true,
    },
  },

  computed: {
    content() {
      const text = this.blog.content.replace(/(<([^>]+)>)/ig, '');
      return text.substring(0, 200);
    },

    contentEn() {
      const text = this.blog.contentEn.replace(/(<([^>]+)>)/ig, '');
      return text.substring(0, 200);
    },
  },

  methods: {
    mouseEnterHandler() {
      this.featchBlog();
    },
    mouseLeaveHandler() {

    },
    featchBlog() {
      if (!this.fullyLoaded && !this.loading) {
        this.loading = true;
        this.$store.dispatch('getBlogById', this.blog.blogId)
          .then(() => {
            this.fullyLoaded = true;
            this.loading = false;
          });
      }
    },

    loadBlog() {
      this.$router.push({ name: 'blog', params: { id: this.blog.blogId } });
      this.$store.dispatch('setCurrentBlogId', this.blog.blogId);
    },
  },
};
</script>

<style>
</style>
