<!-- eslint-disable no-tabs -->
<template>
<!-- ***site header html end*** -->
<main id="content" class="site-main">
<section class="package-inner-page">
  <!-- ***Inner Banner html start form here*** -->
  <div class="inner-banner-wrap">
		<div class="inner-baner-container"
		:style="
				{
				backgroundImage:
						`url(${tour.photoUrl})`
				}">
				<div class="container">
				<div class="inner-banner-content">
						<h1 class="page-title">{{ 'tourDetails' | translate }}</h1>
				</div>
				</div>
		</div>
  </div>
  <!-- ***Inner Banner html end here*** -->
  <!-- ***career section html start form here*** -->
  <div class="inner-package-detail-wrap">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 primary right-sidebar">
          <div class="single-packge-wrap">
			<div class="single-package-head d-flex align-items-center">
				<div class="package-title">
				<h2> {{isCurrentLanguageBs ? tour.name : tour.nameEn}} </h2>
				<div class="rating-start-wrap">
						<div class="rating-start">
								<span style="width: 100%"></span>
						</div>
				</div>
				</div>
				<div class="package-price">
				<h6 class="price-list">
						<span>{{tour.newPrice}}€</span>
						/ {{ 'perPerson' | translate }}
				</h6>
				</div>
			</div>
			<div class="package-meta">
					<ul>
					<li>
							<i class="fas fa-clock"></i>
							{{ tour.trajanje }} {{ dayOrHour}}
					</li>
					<li>
							<i class="fas fa-user-friends"></i>
							max: {{ tour.brojOsobaMax}}
					</li>
					<li>
							<i class="fas fa-swimmer"></i>
							Category : {{tourCategory}}
					</li>

					<li>
							<i class="fas fa-map-marker-alt"></i>
							{{tour.mjestoStart}}
					</li>
					</ul>
			</div>
			<TourSlider v-if="tourImagesUrls?.length > 0" :images="tourImagesUrls" />
			<div class="package-content-detail">
				<article class="package-overview">
				<h3>OVERVIEW :</h3>
				<p>{{isCurrentLanguageBs ? tour.description : tour.descriptionEn}}</p>
				</article>
				<article class="package-include bg-light-grey">
				<ul>
					<li>
							<i class="fas fa-user-minus"></i>
							{{ 'minYear' | translate }}: {{ tour.godineMin }}
					</li>
					<li>
							<i class="fas fa-user-plus"></i>
							{{ 'maxYear' | translate }}: {{ tour.godineMax }}
					</li>
					<li>
							<i class="fas fa-user-friends"></i>
							{{ 'minNoPerson' | translate }}: {{ tour.brojOsobaMin }}
					</li>
					<li>
							<i class="fas fa-user-friends"></i>
							{{ 'maxNoPerson' | translate }}: {{ tour.brojOsobaMax }}
					</li>
					<li>
							<i class="fas fa-clock"></i>
							{{ 'duration' | translate }}:
							{{ tour.trajanje }}
							{{ dayOrHour}}
					</li>
					<li>
							<i class="fas fa-calendar-check"></i>
							{{ 'recomendedPeriod' | translate }}:
							{{ recomendedPeriod }}
					</li>
					<li>
							<i class="fas fa-map-marker-alt"></i>
							{{ 'startPlace' | translate }}: {{ tour.mjestoStart }}
					</li>
					<li>
							<i class="fas fa-map-marker-alt"></i>
							{{ 'finishPlace' | translate }}: {{ tour.mjestoFinish }}
					</li>
					<li>
							<i class="fas fa-umbrella-beach"></i>
							{{ 'equipments' | translate }}: {{ hasEquipment }}
					</li>
					<li>
							<i class="fas fa-headset"></i>
							{{ 'guide' | translate }}: {{ hasGuide }}
					</li>
					<li>
							<i class="fas fa-truck-monster"></i>
							{{ 'transport' | translate }}: {{ hasTransport }}
					</li>
					<li>
							<i class="fas fa-calendar-day"></i>
							{{ 'tourCategory' | translate }}: {{ tourCategory }}
					</li>
					<li>
							<i class="fas fa-chart-line"></i>
							{{ 'activityLevel' | translate }}: {{ activityLevel }}
					</li>
					<li>
							<i class="fas fa-utensils"></i>
							{{ 'foodPackage' | translate }}: {{ foodPackage }}
					</li>
					<li>
							<i class="fas fa-suitcase"></i>
							{{ 'whatToBring' | translate }}: {{ whatToBring }}
					</li>
				</ul>
				</article>
				<article class="package-ininerary">
				<h3>PLAN:</h3>
				<ul>
					<li v-for="(p, index) in tourTimeTable" :key="index">
						<i aria-hidden="true" class="fas fa-dot-circle"></i>
						<span>{{ p.satnica }}</span>
						{{isCurrentLanguageBs ? p.naziv : p.nazivEn}}
					</li>
				</ul>
				</article>
			</div>
          </div>
			<div id="commentArea" class="comment-area">
				<h3 class="comment-title">{{ this.reviews?.length }} {{ 'tourReviews' | translate}}</h3>
				<div class="comment-area-inner">
					<ol>
						<li v-for="review in reviews" :key="review.reviewId">
							<figure class="comment-thumb">
								<star-rating
									:rating="review.rating"
									:star-size="20"
									:read-only="true"
									:increment="1">
								</star-rating>
								<p class="post-on">{{ convertDate(review.dateCreated) }}</p>
							</figure>
							<div class="comment-content">
								<div class="comment-header">
										<h5 class="author-name">
											{{ review.name }}</h5>
										<span class="post-on">| {{ review.city }}, {{ review.country }}</span>
								</div>
								<p>{{ review.message }}</p>
							</div>
						</li>
					</ol>
				</div>
				<ReviewForm />
			</div>
        </div>
        <div class="col-lg-4">
			<div class="sidebar">
				<TourCheckAvailability />
				<div class="package-map">
						<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10592.855276363387!2d17.746186343504295!3d43.62948920036189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475f5f85ca4ffe7d%3A0xfd5b5767b515f2f3!2sVisit%20Jablanica!5e0!3m2!1sen!2sba!4v1679245676908!5m2!1sen!2sba" width="600" height="320" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
				</div>
				<div class="package-list">
					<div class="overlay"></div>
					<h4>{{ 'recomendedTours' | translate}}</h4>
					<ul>
						<li v-for="tour in recommendedTours" :key="tour.tourId">
							<a @click.stop="loadTour(tour.tourId)" class="cp">
								<i aria-hidden="true"
								class="fa fa-arrow-circle-right"></i>
								{{isCurrentLanguageBs ? tour.name : tour.nameEn}}
							</a>
						</li>
					</ul>
				</div>
			</div>
        </div>
      </div>
    </div>
  </div>
<!-- ***career section html start form here*** -->
</section>
</main>
</template>

<script>
import TourSlider from '@/components/widgets/TourSlider';
import StarRating from 'vue-star-rating';
import convertDate from '@/mixins/dateConvert';
import ReviewForm from '@/components/widgets/ReviewForm';
import TourCheckAvailability from '@/components/widgets/TourCheckAvailability';

export default {
  name: 'TourDetails',

  mixins: [convertDate],

  components: {
    TourSlider,
    StarRating,
    ReviewForm,
    TourCheckAvailability,
  },

  data() {
    return {
    };
  },

  created() {
    this.$store.dispatch('setCurrentTourId', this.$route.params.id);
    this.$store.dispatch('getTourById', this.$route.params.id)
      .then(() => {
        this.$nextTick(() => {
          this.onPageLoad();
        });
      });
  },

  computed: {
    currentTourId() {
      return this.$store.getters.currentTourId;
    },
    tour() {
      return this.$store.getters.flatTours[this.currentTourId || this.$route.params.id] || {};
    },

    reviews() {
      return this.tour.reviews || [];
    },

    tourImagesUrls() {
      return this.tour?.tourImages?.map((tour) => tour.url);
    },

    dayOrHour() {
      return this.tour.tourCategoryId === 1
        ? this.translate('hours')
        : this.translate('days');
    },

    hasEquipment() {
      return this.tour.oprema ? this.translate('yes') : this.translate('no');
    },

    hasGuide() {
      return this.tour.vodic ? this.translate('yes') : this.translate('no');
    },

    hasTransport() {
      return this.tour.transport ? this.translate('yes') : this.translate('no');
    },

    whatToBring() {
      return this.isCurrentLanguageBs ? this.tour.staPonjetiOprema : this.tour.staPonjetiOpremaEn;
    },

    recomendedPeriod() {
      return this.isCurrentLanguageBs ? this.tour.preporuceniPeriod : this.tour.preporuceniPeriodEn;
    },

    foodPackage() {
      const foodPackage = this.$store.getters.foodPackages(this.tour.foodPackageId);
      return this.isCurrentLanguageBs ? foodPackage.name : foodPackage.nameEn;
    },

    activityLevel() {
      const activityLevel = this.$store.getters.activityLevels(this.tour.activityLevelId);
      return this.isCurrentLanguageBs ? activityLevel.name : activityLevel.nameEn;
    },

    tourCategory() {
      const category = this.$store.getters.tourCategory(this.tour.tourCategoryId);
      return this.isCurrentLanguageBs ? category.name : category.nameEn;
    },

    tourTimeTable() {
      return this.tour.planAktivnosti;
    },

    recommendedTours() {
      return this.$store.getters.recommendedTours;
    },
  },

  methods: {
    featchTour(id = this.$route.params.id) {
      this.$store.dispatch('setCurrentTourId', id);
      this.$store.dispatch('getTourById', id)
        .then(() => {});
      return Promise.resolve();
    },
    loadTour(id) {
      if (!this.$store.getters.flatTours[id]) {
        this.featchTour(id);
      }
      this.$store.dispatch('setCurrentTourId', id);
      this.$router.push({ name: 'tour', params: { id } });
      // if (this.fullyLoaded && !this.loading) {}
    },
  },
};
</script>

<style scoped>
</style>
