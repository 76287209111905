<template>
    <figure class="single-package-image">
        <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
          <swiper-slide
            :class="`slide-${index + 1}`"
            v-for="(slide, index) in images" :key="index">
            <img :src="slide" width="100%"/>
          </swiper-slide>
        </swiper>
        <!-- swiper2 Thumbs -->
        <swiper class="swiper gallery-thumbs"
          @slideChange="onThumbnailChange"
          :options="swiperOptionThumbs" ref="swiperThumbs">
          <swiper-slide
            :class="`slide-${index + 1}`"
            v-for="(slide, index) in images" :key="index">
            <img :src="slide"/>
          </swiper-slide>
          <template v-slot:button-prev>
            <div
              @click="slidePrev()"
              class="swiper-button-prev"
            ></div>
          </template>
          <template v-slot:button-next>
            <div
              @click="slideNext()"
              class="swiper-button-next"
            ></div>
          </template>
        </swiper>
    </figure>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.min.css';

export default {
  name: 'TourSlider',
  data() {
    return {
      swiperOptionTop: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
      },
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    images: {
      type: Array,
      require: true,
    },
  },
  mounted() {
  },

  computed: {
  },
  methods: {
    slidePrev() {
      this.$refs.swiperThumbs.swiperInstance.slidePrev();
    },
    slideNext() {
      this.$refs.swiperThumbs.swiperInstance.slideNext();
    },
    onThumbnailChange(val) {
      this.$refs.swiperTop.$swiper.slideTo(val.activeIndex);
    },
  },
};
</script>
    <style scoped>
    .swiper .swiper-slide {
      background-size: cover;
      background-position: center;
    }

    .swiper.gallery-top {
      max-height: 80%;
      width: 100%;
      border-radius: 25px;
    }
    .swiper.gallery-thumbs {
      max-height: 20%;
      box-sizing: border-box;
      margin-top: 5px;
      border-radius: 25px;
    }
    .swiper.gallery-thumbs .swiper-slide {
      width: 25%;
      height: 100%;
      opacity: 0.4;
    }
    .swiper.gallery-thumbs .swiper-slide-active {
      opacity: 1;
    }
</style>
