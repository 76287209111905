<template>
    <div class="image-modal">
    <div class="modal-overlay" @click="$emit('close')"></div>
    <div class="modal-content">
      <!-- <button class="modal-close" @click="$emit('close')">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button> -->
      <img :src="images[currentIndex].url" alt="Visit Jablanica">
      <button @click.stop="prevImage"><i class="fa fa-arrow-left" aria-hidden="true"></i></button>
      <button @click.stop="nextImage"><i class="fa fa-arrow-right" aria-hidden="true"></i></button>
    </div>
  </div>
  </template>

<script>
export default {
  props: {
    images: Array,
    currentIndex: Number,
  },
  methods: {
    nextImage() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    prevImage() {
      this.currentIndex = (this.currentIndex + this.images.length - 1) % this.images.length;
    },
  },
};
</script>

<style scoped>
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-content {
  position: relative;
  padding: 0px;
  background-color: #fff;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  border: none;
}

.modal-content img {
  max-width: 100%;
  height: auto;
}

.modal-content button:not(.modal-close) {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #505254;
  border: none;
  color: #fff;
  font-size: 1.5em;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
}

.modal-content button:first-of-type {
  left: 20px;
}

.modal-content button:last-of-type {
  right: 20px;
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #505254;
  border: none;
  color: #ccc;
  font-size: 1.5em;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
}
</style>
