<!-- eslint-disable no-tabs -->
<template>
	<div id="replyForm" class="comment-form-wrap">
		<h3 class="comment-title">{{ 'addReview' | translate}}</h3>
		<p>{{ 'reviewInfoMessage' | translate}}</p>
		<form class="comment-form" @submit.prevent="submitForm">
			<p class="full-width">
				<label>{{ 'message' | translate}} *</label>
				<textarea rows="4" name="comment" v-model="comment"
					v-validate="'required'"></textarea>
				<span
					v-show="errors.has('comment')"
					class="invalid-feedback">
					{{ translate('requiredValid') }}
				</span>
			</p>
			<p>
				<label>{{ 'name' | translate}} *</label>
				<input type="text" name="commentName" v-model="commentName"
					v-validate="'required'">
				<span
					v-show="errors.has('commentName')"
					class="invalid-feedback">
					{{ translate('requiredValid') }}
				</span>
			</p>
			<p>
				<label>{{ 'email' | translate}} *</label>
				<input type="text" v-model="commentEmail"
					name="commentEmail"
					v-validate="'required|email'">
				<span v-show="errors.has('commentEmail')"
					class="invalid-feedback">
					{{ translate('emailValid') }}</span>
			</p>
			<p>
				<label>{{ 'city' | translate}} *</label>
				<input type="text" name="city" v-model="city" v-validate="'required'">
				<span
					v-show="errors.has('city')"
					class="invalid-feedback">
					{{ translate('requiredValid') }}
				</span>
			</p>
			<p>
				<label>{{ 'country' | translate}} *</label>
				<input type="text" name="country" v-model="country" v-validate="'required'">
				<span
					v-show="errors.has('country')"
					class="invalid-feedback">
					{{ translate('requiredValid') }}
				</span>
			</p>
			<p>
				<star-rating :increment="1" v-model="rating"></star-rating>
			</p>
			<p class="full-width">
				<input type="submit" name="submit" :value="translate('postReview')">
			</p>
		</form>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating';

export default {
  name: 'ReviewForm',

  components: {
    StarRating,
  },

  data() {
    return {
      comment: '',
      commentName: '',
      commentEmail: '',
      city: '',
      country: '',
      rating: 0,
    };
  },

  computed: {
    currentTourId() {
      return this.$store.getters.currentTourId;
    },
  },

  methods: {
    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const review = {
            name: this.commentName,
            email: this.commentEmail,
            message: this.comment,
            city: this.city,
            country: this.country,
            rating: this.rating,
            tourId: this.currentTourId,
            dateCreated: new Date(),
          };
          this.$store.dispatch('createReview', { tourId: this.currentTourId, review })
            .then(() => {
              this.$toast.success(this.translate('reviewAdded'));
              this.comment = '';
              this.commentName = '';
              this.commentEmail = '';
              this.city = '';
              this.country = '';
              this.rating = 0;
            }, () => {
              this.$toast.error(this.translate('reviewNotAdded'));
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.invalid-feedback {
  display: block;
  color: red;
  font-size: 14px;
}
</style>
