<template>
    <article
        class="package-item"
        @mouseenter="mouseEnterHandler"
        @mouseleave="mouseLeaveHandler">
    <figure class="package-image"
        :style="{ backgroundImage: `url(${tour.photoUrl})` }">
    </figure>
    <div class="package-content">
        <h3>
            <a @click="loadTour">
                {{isCurrentLanguageBs ? tour.name : tour.nameEn}}
            </a>
        </h3>
        <p class="package-item-desc">
            {{ isCurrentLanguageBs
                ? tour.shortDescription : tour.shortDescriptionEn}}</p>
        <div class="package-meta">
            <ul>
                <li>
                <i class="fas fa-clock"></i>
                {{tourCategory}}
                </li>
                <li>
                <i class="fas fa-user-friends"></i>
                min: {{ personsMin}}
                </li>
                <li>
                <i class="fas fa-map-marker-alt"></i>
                Jablanica
                </li>
            </ul>
        </div>
    </div>
    <div class="package-price">
        <div class="review-area">
            <span class="review-text">
                ({{tour.reviews.length}} {{ 'reviews' | translate }})
            </span>
            <div class="rating-start-wrap d-inline-block">
                <div class="rating-start">
                <span style="width: 100%"></span>
                </div>
            </div>
        </div>
        <h6 class="price-list">
            <span>€{{tour.newPrice}}</span>
            / {{ 'perPerson' | translate }}
        </h6>
        <a
            class="outline-btn outline-btn-white"
            @click="loadTour">
            {{ 'bookNow' | translate }}
        </a>
    </div>
    </article>
</template>
<script>

export default {
  name: 'TourListItem',

  data() {
    return {
      fullyLoaded: false,
      loading: false,
    };
  },

  props: {
    tour: {
      type: Object,
      required: true,
    },
  },

  computed: {
    tourCategory() {
      const category = this.$store.getters.tourCategory(this.tour.tourCategoryId);
      return this.isCurrentLanguageBs ? category.name : category.nameEn;
    },
    personsMin() {
      return this.$store.getters.flatTours[this.tour.tourId]?.brojOsobaMin || 0;
    },
  },

  methods: {
    mouseEnterHandler() {
      this.featchTour();
    },
    mouseLeaveHandler() {

    },
    featchTour() {
      if (!this.fullyLoaded && !this.loading) {
        this.loading = true;
        this.$store.dispatch('getTourById', this.tour.tourId)
          .then(() => {
            this.fullyLoaded = true;
            this.loading = false;
          });
      }
    },

    loadTour() {
      this.$router.push({ name: 'tour', params: { id: this.tour.tourId } });
      this.$store.dispatch('setCurrentTourId', this.tour.tourId);
      // if (this.fullyLoaded && !this.loading) {}
    },
  },
};
</script>

<style>
</style>
