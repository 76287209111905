<template>
<main id="content" class="site-main">
  <section class="inner-page-wrap">
      <!-- ***Inner Banner html start form here*** -->
      <div class="inner-banner-wrap">
        <div class="inner-baner-container"
        :style="{
          backgroundImage:
            `url(${require('@/assets/images/aboutUsBanner.webp')})`
        }">
            <div class="container">
              <div class="inner-banner-content">
                  <h1 class="page-title">{{ 'about' | translate }}</h1>
              </div>
            </div>
        </div>
      </div>
      <!-- ***Inner Banner html end here*** -->
      <!-- ***about section html start form here*** -->
      <div class="inner-about-wrap">
        <div class="container">
            <div class="row">
              <div class="col-lg-8">
                  <div class="about-content">
                    <figure class="about-image">
                        <img src="../../assets/images/aboutImage.webp" alt="">
                        <div class="about-image-content">
                          <h3>{{ 'aboutTitle' | translate }}!</h3>
                        </div>
                    </figure>
                    <p>{{ translate('aboutText')}}</p>
                  </div>
              </div>
              <div class="col-lg-4">
                  <div class="icon-box">
                    <div class="box-icon">
                        <i aria-hidden="true" class="fas fa-umbrella-beach"></i>
                    </div>
                    <div class="icon-box-content">
                        <h3>{{ 'affordableTours' | translate }}</h3>
                        <p>{{ 'affordableToursInfo' | translate }}</p>
                    </div>
                  </div>
                  <div class="icon-box">
                    <div class="box-icon">
                        <i aria-hidden="true" class="fas fa-user-tag"></i>
                    </div>
                    <div class="icon-box-content">
                        <h3>{{ 'bestGuides' | translate }}</h3>
                        <p>{{ 'bestGuidesInfo' | translate }}</p>
                    </div>
                  </div>
                  <div class="icon-box">
                    <div class="box-icon">
                        <i aria-hidden="true" class="fas fa-leaf"></i>
                    </div>
                    <div class="icon-box-content">
                        <h3>{{ 'Eco-friendly' | translate }}</h3>
                        <p>{{ 'ecoFriendlyInfo' | translate }}</p>
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </div>
      <!-- ***about section html start form here*** -->
  </section>
</main>
</template>

<script>
export default {
  name: 'AboutUs',
};
</script>

<style lang="scss" scoped>
</style>
