<template>
    <main id="content" class="site-main">
    <!-- ***Inner Banner html start form here*** -->
    <div class="inner-banner-wrap">
        <div class="inner-baner-container"
        :style="{
          backgroundImage:
            `url(${require('@/assets/images/img7.jpg')})`
        }">
          <div class="container">
              <div class="inner-banner-content">
                <h1 class="page-title">{{isCurrentLanguageBs ? blog.title : blog.titleEn}}</h1>
              </div>
          </div>
        </div>
    </div>
    <!-- ***Inner Banner html end here*** -->
    <div class="single-post-section">
        <div class="single-post-inner">
          <div class="container">
              <div class="row">
                <div class="col-lg-8 primary right-sidebar">
                    <!-- single blog post html start -->
                    <figure class="feature-image">
                      <img :src="blog.imageUrl" alt="">
                    </figure>
                    <div class="entry-meta">
                      <span class="posted-on">
                        <i class="fa fa-calendar-day"></i>
                        {{ convertDate(blog.dateAdded) }}
                      </span>
                    </div>
                    <article class="single-content-wrap">
                      <div v-html="isCurrentLanguageBs ? blog.content : blog.contentEn"></div>
                    </article>
                    <div class="meta-wrap">
                      <div class="tag-links">
                          <span><strong>Destination</strong></span>,
                          <span><strong>hiking</strong></span>,
                          <span><strong>Travel Dairies</strong></span>,
                          <span><strong>Travelling</strong></span>,
                          <span><strong>Trekking</strong></span>
                      </div>
                    </div>
                </div>
                <div class="col-lg-4 secondary">
                    <div class="sidebar">
                      <aside class="widget widget_latest_post widget-post-thumb">
                          <h3 class="widget-title">{{ 'ourRecentPosts' | translate }}</h3>
                          <ul>
                            <li v-for="blog in lastAddedBlogs"
                              :key="blog.blogId"
                              @click.stop="openBlog(blog.blogId)"
                              class="cp">
                              <figure class="post-thumb">
                                  <img :src="blog.imageUrl" alt="">
                              </figure>
                              <div class="post-content">
                                <h5>
                                  {{ isCurrentLanguageBs ? blog.title : blog.titleEn }}
                                </h5>
                                <div class="entry-meta">
                                    <span class="posted-on">
                                      {{ convertDate(blog.dateAdded) }}
                                    </span>
                                </div>
                              </div>
                            </li>
                          </ul>
                      </aside>
                      <aside class="widget widget_latest_post widget-post-thumb">
                          <h3 class="widget-title">{{ 'recomendedTours' | translate }}</h3>
                          <ul>
                            <li v-for="tour in recomendedTours" :key="tour.tourId"
                              @click.stop="openTour(tour.tourId)"
                              class="cp">
                              <figure class="post-thumb">
                                <img :src="tour.photoUrl" alt="">
                              </figure>
                              <div class="post-content">
                                <h5>
                                    {{isCurrentLanguageBs ? tour.name : tour.nameEn}}
                                </h5>
                                <div class="entry-meta">
                                    <span class="posted-on">
                                      <b>
                                        €{{tour.newPrice}}
                                      </b>
                                    </span>
                                    <span class="comments-link btn">
                                      {{ 'bookNow' | translate }}
                                    </span>
                                </div>
                              </div>
                            </li>
                          </ul>
                      </aside>
                    </div>
                </div>
              </div>
          </div>
        </div>
    </div>
  </main>
</template>

<script>
import convertDate from '@/mixins/dateConvert';

export default {
  name: 'BlogDetails',

  mixins: [convertDate],

  created() {
    this.$store.dispatch('setCurrentBlogId', this.$route.params.id);
  },

  computed: {
    blog() {
      return this.$store.getters.flatBlogs[this.currentBlogId || this.$route.params.id] || {};
    },
    blogs() {
      return this.$store.getters.blogs;
    },
    currentBlogId() {
      return this.$store.getters.currentBlogId;
    },
    lastAddedBlogs() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.blogs
        .sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded))
        .filter((blog) => blog.blogId !== this.currentBlogId)
        .slice(0, 3);
    },
    recomendedTours() {
      return this.$store.getters.recommendedTours;
    },
  },

  methods: {
    openBlog(id) {
      this.$store.dispatch('setCurrentBlogId', id);
      this.$router.push({ name: 'blog', params: { id } });
    },
    openTour(id) {
      this.$store.dispatch('setCurrentTourId', id);
      this.$router.push({ name: 'tour', params: { id } });
    },
  },
};
</script>

<style scoped>
</style>
