<!-- eslint-disable no-tabs -->
<template>
	<main id="content" class="site-main">
		<!-- ***Inner Banner html start form here*** -->
		<div class="inner-banner-wrap">
				<div class="inner-baner-container"
				:style="{
						backgroundImage:
							`url(${require('@/assets/images/galleryBanner.webp')})`
					}">
					<div class="container">
							<div class="inner-banner-content">
								<h1 class="page-title">{{ 'gallery' | translate }}</h1>
							</div>
					</div>
				</div>
		</div>
			<!-- ***Inner Banner html end here*** -->
		<!-- gallery section html start -->
		<div class="gallery-section">
				<div class="container">
					<div class="gallery-outer-wrap">
						<div class="gallery-container">
							<figure v-for="(image, index) in gallery" :key="index" class="gallery-item">
								<img
									@click="showImageModal(index)"
									:src="image.url" alt="Visit Jablanica" class="gallery-image" />
							</figure>
						</div>
						<ImageModal
							v-if="showModal"
							:images="gallery"
							:currentIndex="currentIndex"
							@close="closeModal" />
					</div>
				</div>
		</div>
	</main>
</template>

<script>

import ImageModal from '@/components/widgets/ImageModal';

export default {
  name: 'Gallery',

  components: {
    ImageModal,
  },

  computed: {
    gallery() {
      return this.$store.getters.galleryImages;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.onPageLoad();
    });
  },

  data() {
    return {
      showModal: false,
      currentIndex: 0,
    };
  },

  methods: {
    showImageModal(index) {
      this.currentIndex = index;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style>
.gallery-image {
  max-width: 100%;
  display: block;
  border-radius: 10px;
}

.gallery-item {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}

.gallery-item > img {
  grid-row: 1 / -1;
  grid-column: 1;
}

.gallery-container {
  column-count: 4;
  column-gap: 5px;
}
</style>
