<template>
    <!-- ***site header html end*** -->
    <main id="content" class="site-main">
        <section class="inner-page-wrap">
            <!-- ***Inner Banner html start form here*** -->
            <div class="inner-banner-wrap">
                <div class="inner-baner-container"
                :style="
                {
                backgroundImage:
                    `url(${require('@/assets/images/baner_tours.jpg')})`
                }">
                    <div class="container">
                    <div class="inner-banner-content">
                        <h1 class="page-title">{{ 'tours' | translate }}</h1>
                    </div>
                    </div>
                </div>
            </div>
            <!-- ***Inner Banner html end here*** -->
            <!-- ***package section html start form here*** -->
            <div class="package-item-wrap">
                <div class="container">
                  <TourListItem v-for="(tour, index) in tours" :key="index" :tour="tour" />
                </div>
            </div>
            <!-- ***package section html start form here*** -->
        </section>
    </main>
    <!-- ***site footer html start form here*** -->
</template>
<script>

import TourListItem from '@/components/widgets/TourListItem';

export default {
  name: 'TourList',

  components: {
    TourListItem,
  },

  mounted() {
    this.$nextTick(() => {
      this.onPageLoad();
    });
  },

  data() {
    return {
    };
  },
  computed: {
    tours() {
      return this.$store.getters.tours;
    },
  },
  methods: {
  },
};
</script>
